@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f4f4f2;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  @apply px-5 mx-auto max-w-screen-xl 2xl:max-w-[1650px];
}

.news-container {
  @apply px-5 mx-auto max-w-[880px];
}

.icon {
  @apply block w-5 h-5 flex-shrink-0;
}

.button {
  @apply flex items-center justify-center text-[13px] text-center transition-colors duration-200 py-5 px-5 lg:px-6 focus-visible:ring-accent focus-visible:ring-2 focus:outline-none whitespace-nowrap disabled:cursor-default disabled:pointer-events-none uppercase font-medium;
}

.button-primary {
  @apply bg-black hover:bg-neutral-900 text-white;
}

.button-secondary {
  @apply bg-white text-black hover:opacity-90 transition-all;
}

.heading-1 {
  @apply text-5xl lg:text-[88px] leading-none lg:leading-[1.2];
}

.heading-2 {
  @apply text-4xl lg:text-[80px] leading-none lg:leading-[1.175];
}

.subheading-1 {
  @apply text-sm lg:text-xl !leading-normal;
}

.subheading-2 {
  @apply text-[15px] lg:text-2xl leading-[1.35];
}

.py-section {
  @apply py-10 lg:py-22;
}

.pt-section {
  @apply pt-10 lg:pt-22;
}

.pb-section {
  @apply pb-10 lg:pb-22;
}

.h-screen-with-header {
  @apply min-h-[calc(100vh-88px)] lg:min-h-[calc(100vh-128px)];
}

.link {
  @apply text-accent hover:text-accent-600 transition-all underline;
}

/* News content styling */
.news-h1 {
  @apply text-4xl;
}
.news-h2 {
  @apply text-3xl;
}
.news-h3 {
  @apply text-2xl;
}
.news-h4 {
  @apply text-xl;
}
.news-h5 {
  @apply text-lg;
}
.news-h6 {
  @apply text-base;
}
.news-p {
  @apply text-base mb-[30px];
}
.news-link {
  @apply font-medium text-accent hover:underline;
}
.news-ol {
  @apply list-decimal;
}
.news-ol li {
  @apply ml-4;
}
.news-ul {
  @apply list-disc;
}
.news-ul li {
  @apply ml-4;
}

/* Loader */
.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.loader div {
  position: absolute;
  border: 2px solid;
  opacity: 1;
  border-radius: 50%;
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes loader {
  0% {
    top: 20px;
    left: 20px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.jetsonLinksStyle a {
  color: #ea9b3b;
}

.formHeadings h3 {
 @apply lg:text-xl
}
